import React, { useState } from 'react';
import arrowdown from '../../../images/LichKabinet/transaction/arrowdown.png';
import calendar from '../../../images/LichKabinet/transaction/calendar.png';
import down from '../../../images/LichKabinet/transaction/down.png';
import up from '../../../images/LichKabinet/transaction/up.png';
import arrowleft from '../../../images/LichKabinet/transaction/left.png';
import arrowright from '../../../images/LichKabinet/transaction/right.png';
// import { transactions } from './transaction';
const RecentTransactionsComponent = () => {
    const [selected, setSelected] = useState('all');
    const [currentPage, setCurrentPage] = useState(1);
    const transactionsPerPage = 5;

    const transactions = []

    const filterTransactions = () => {
        if (selected === 'income') {
            return transactions.filter(transaction => transaction.amount > 0);
        } else if (selected === 'expense') {
            return transactions.filter(transaction => transaction.amount < 0);
        }
        return transactions;
    };

    const filteredTransactions = filterTransactions();
    const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

    const currentTransactions = filteredTransactions.slice(
        (currentPage - 1) * transactionsPerPage,
        currentPage * transactionsPerPage
    );

    const changePage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className='recent-transactions'>
            <h1 className='h1-statistic-account'>Recent Transactions</h1>
            <main className='main-transaction-head'>
                <section className='h1-section-recent'>
                    <h1
                        className={selected === 'all' ? 'selected' : 'not-selected'}
                        onClick={() => {
                            setSelected('all');
                            setCurrentPage(1);
                        }}
                    >
                        All Transactions
                    </h1>
                    <h1
                        className={selected === 'income' ? 'selected' : 'not-selected'}
                        onClick={() => {
                            setSelected('income');
                            setCurrentPage(1);
                        }}
                    >
                        Income
                    </h1>
                    <h1
                        className={selected === 'expense' ? 'selected' : 'not-selected'}
                        onClick={() => {
                            setSelected('expense');
                            setCurrentPage(1);
                        }}
                    >
                        Expense
                    </h1>
                </section>
                <section className='calendar-section-transaction'>
                    <div>
                        <img src={calendar} alt='Calendar' />
                        <p>10 May - 20 May</p>
                    </div>
                    <img src={arrowdown} alt='Arrow Down' />
                </section>
            </main>

            <main className='all-transactions-recent'>
                <div className='h2-section-top-all-transaction'>
                    <h2 className='description-transaction'>Description</h2>
                    <h2 className='transactionid-transaction'>Transaction ID</h2>
                    <h2 className='type-transaction'>Type</h2>
                    <h2 className='card-transaction'>Card</h2>
                    <h2 className='date-transaction'>Date</h2>
                    <h2 className='amount-transaction'>Amount</h2>
                    <h2 className='receipt-transaction'>Receipt</h2>
                </div>
                {currentTransactions.length === 0 ? (
                    <div className="no-transactions-message">
                        No transactions yet
                    </div>
                ) : (
                    currentTransactions.map((transaction, index) => (
                        <div key={index} className='info-transaction-div'>
                            <section className='description-transaction'>
                                <img src={transaction.direction === 'up' ? up : down} alt='Direction' />
                                <h2>{transaction.description}</h2>
                            </section>
                            <h2 className='transactionid-transaction'>{transaction.transactionId}</h2>
                            <h2 className='type-transaction'>{transaction.type}</h2>
                            <h2 className='card-transaction'>{transaction.card}</h2>
                            <h2 className='date-transaction'>{transaction.date}</h2>
                            <span className={`amount-transaction ${transaction.amount < 0 ? 'transaction-minus' : 'transaction-plus'}`}>
                                {transaction.amount < 0 ? `-$${Math.abs(transaction.amount)}` : `$${transaction.amount}`}
                            </span>
                            <button>{transaction.receipt}</button>
                        </div>
                    ))
                )}
            </main>

            {filteredTransactions.length > 0 && (<div className='pagination-container'>
                <button
                    onClick={() => changePage(currentPage - 1)}
                    disabled={currentPage === 1}
                    className='pagination-container-buttons-slide'
                >
                    <img src={arrowleft} alt="Previous" />
                    Previous
                </button>

                {Array.from({ length: totalPages }, (_, index) => (
                    <button
                        key={index}
                        className={currentPage === index + 1 ? 'pagination-container-buttons active-page' : 'pagination-container-buttons un-active-page'}
                        onClick={() => changePage(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}

                <button
                    onClick={() => changePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className='pagination-container-buttons-slide'
                >
                    Next <img src={arrowright} alt="Next" />
                </button>
            </div>)}
        </div>
    );
};

export default RecentTransactionsComponent;
