import React from 'react';

import image1 from '../../../images/LichKabinet/investmen/totalinvest.png'
import image2 from '../../../images/LichKabinet/investmen/numberof.png'
import image3 from '../../../images/LichKabinet/investmen/returned.png'
const TrendingStock = () => {
    const companies = [
        { name: 'Trivago', price: '$520', return: '+5%', isPositive: true },
        { name: 'Canon', price: '$480', return: '+10%', isPositive: true },
        { name: 'Uber Food', price: '$350', return: '-3%', isPositive: false },
        { name: 'Tesla', price: '$720', return: '+8%', isPositive: true },
        { name: 'Amazon', price: '$1,020', return: '-2%', isPositive: false }
    ];

    return (
        <div className='trending-stock-component'>
            <h1 className='h1-statistic-account'>Trending Stocks</h1>
            <div className='trending-stocks-display'>
                <section className='treding-stock-companys'>
                    <div className='h2-section-div-trending'>
                        <h2 className='h2-sl-no-ts'>SL No</h2>
                        <h2 className='h2-name-ts'>Name</h2>
                        <h2 className='h2-price-ts'>Price</h2>
                        <h2 className='h2-return-ts'>Return</h2>
                    </div>
                    {companies.map((company, index) => (
                        <div className='trending-stock-company' key={index}>
                            <h3 className='h2-sl-no-ts'>{`0${index + 1}.`}</h3>
                            <h3 className='h2-name-ts'>{company.name}</h3>
                            <h3 className='h2-price-ts'>{company.price}</h3>
                            <span className={`h2-return-ts ${company.isPositive ? 'transaction-plus' : 'transaction-minus'}`}>
                                {company.return}
                            </span>
                        </div>
                    ))}
                </section>

                <section className='calculated-section-investments'>
                    <div className='investments-info-calculated'>
                        <img src={image1} />
                        <div>
                            <h1>Total Invested Amount</h1>
                            <h2>$0</h2>
                        </div>
                    </div>
                    <div className='investments-info-calculated'>
                        <img src={image2} />
                        <div>
                            <h1>Number of Investments</h1>
                            <h2>0</h2>
                        </div>
                    </div>
                    <div className='investments-info-calculated'>
                        <img src={image3} />
                        <div>
                            <h1>Rate of Return</h1>
                            <h2>+0%</h2>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default TrendingStock;
