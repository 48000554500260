import React from 'react';

import cross from '../../images/LichKabinet/cross.png'

const Notifications = () => {

    return (
        <main className='notifications-lich'>
            <section>
                <div>
                    <img src={cross} />
                    <p> На внутренний баланс зачислено <span>3000 USD</span></p>
                    <h2>25.01.2021 02:00:00</h2>
                </div>
                <div>
                    <img src={cross} />
                    <p>Вы отправили заявку на вывод средств,
                        время обработки до <span>24 часов.</span></p>
                        <h2>25.01.2021 02:00:00</h2>
                </div>
                <div>
                    <img src={cross} />
                    <p>Ваша текущая квалификация <span>1 уровень</span></p>
                    <h2>25.01.2021 02:00:00</h2>
                </div>
                <div>
                    <img src={cross} />
                    <p> На внутренний баланс зачислено <span>50 USD</span></p>
                    <h2>25.01.2021 02:00:00</h2>
                </div>
            </section>
            <section className='section-button'><button>Очистить список</button></section>
        </main>
    );
};

export default Notifications;
