import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import MyAccounts from '../components/LichKabinet/homepage/MyAccounts';
import MyExpenseComponent from '../components/LichKabinet/transactions/MyExpenseComponent';
import RecentTransactionsComponent from '../components/LichKabinet/transactions/RecentTransactionsComponent';
import ReferalLink from '../components/LichKabinet/ReferalLink';


function MyTransaction() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", justifyContent: "space-between", gap: "20px" }}>
                <MyprofileComponent />
                <div className='center-my-transactions'>
                    <MyAccounts />
                    {/* <MyExpenseComponent /> */}
                    <RecentTransactionsComponent />
                </div>
            </div>


        </div>
    );
}

export default MyTransaction;
