import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import back from '../../images/FirstComponent/FirstComponentBack.gif'


const PrivateRoute = ({ children }) => {
    const [isAuth, setIsAuth] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const verifyToken = async () => {
            if (token) {
                try {
                    const response = await axios.get('https://web.fundlumina.com/api/verify-token', {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                    setIsAuth(true);
                } catch (error) {
                    localStorage.removeItem('token');
                    setIsAuth(false);
                }
            } else {
                setIsAuth(false);
            }
        };

        verifyToken();
    }, [token]);


    if (isAuth === null) {
        return <div className='infinity-loading'>
            <img src={back} />
        </div>;
    }

    if (!isAuth) {
        return <Navigate to="/regestration?login=on" />;
    }

    return children;
};

export default PrivateRoute;