import { Link } from "react-router-dom";
import subs from '../../../images/LichKabinet/transaction/subscribe.png';
import service from '../../../images/LichKabinet/transaction/services.png';
import user from '../../../images/LichKabinet/transaction/user.png';
import { useContext } from "react";
import { UserContext } from "../UserContext";

const LastTransactions = () => {
    const { transactions } = useContext(UserContext);

    const recentTransactions = Array.isArray(transactions) ? transactions.slice(-3).reverse() : [];

    return (
        <main className="last-transaction-component">
            <section className="top-statistic-account">
                <h1 className='h1-statistic-account'>Last Transaction</h1>
                <Link to='/lk/mytransactions'>See All</Link>
            </section>

            <section className="section-last-transactions">
                {recentTransactions.length > 0 ? (
                    recentTransactions.map((transaction, index) => (
                        <div className="transaction-div" key={index}>
                            <img src={getImageForType(transaction.type)} alt={transaction.type} />
                            <section className="transaction-info">
                                <h2>{transaction.name}</h2>
                                <p>{transaction.date}</p>
                            </section>
                            <section className="transaction-discription">
                                <p>{transaction.category}</p>
                                <p>{transaction.account}</p>
                                <p>{transaction.id}</p>
                                <p>{transaction.status}</p>
                                <span className={transaction.amount > 0 ? "transaction-plus" : "transaction-minus"}>
                                    {transaction.amount > 0 ? `+${transaction.amount}` : `-${Math.abs(transaction.amount)}`}
                                </span>
                            </section>
                        </div>
                    ))
                ) : (
                    <div className="no-transactions-message" style={{ padding: "0" }}>
                        No transactions yet
                    </div>
                )}
            </section>
        </main>
    );
};

const getImageForType = (type) => {
    switch (type) {
        case 'subscription':
            return subs;
        case 'service':
            return service;
        case 'user':
            return user;
        default:
            return '';
    }
};

export default LastTransactions;