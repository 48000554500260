import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import logo from '../../images/Header/headerlogo.png';

const LoginComponent = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);

    const referal = params.get('id') || 'LuminaInvest';

    const handleLogin = async () => {
        try {
            const response = await axios.post('https://web.fundlumina.com/api/login', {
                email,
                password,
            }, { withCredentials: true });
            localStorage.setItem('token', response.data.token);
            navigate("/lk");
        } catch (error) {
            alert('Login failed. Please check your credentials.');
            console.error('Login error:', error);
        }
    };

    return (
        <div className="regestration-component">
            <img src={logo} className="absolut-regestration-component" onClick={() => { navigate(`/?id=${referal}`) }} />
            <main className="log-in-main">
                <h1>Log in to your personal account</h1>
                <section>
                    <p className="regestration-component-text-first">
                        Manage your account and transactions with all tools and assets on your favorite platform. Terms apply. Trading is risky.
                    </p>
                    <div className="log-in-inputs">
                        <input
                            placeholder="Personal Account Number or E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button onClick={handleLogin}>Log in</button>
                    </div>
                    <h2 className="regestration-component-text-second">
                        Security Rules: If anyone, including Lumina employees, asks you to provide the password to your Personal Account, do not share it. This is confidential information.
                    </h2>
                    <div className="regestration-component-end-div">
                        <p>Can’t log in? <span>Contact us.</span></p>
                        <Link to={`/forgotpassword`}>Forgot Password?</Link>
                        <Link to="#">Forgot E-mail?</Link>
                        <Link to={`/regestration?id=${referal}`}>Sign up</Link>
                    </div>
                </section>

                <footer>
                    <Link to="#">Privacy Policy</Link>
                    <Link to="#">Terms</Link>
                    <Link to="#">© 2023 LIF.</Link>
                </footer>
            </main>
        </div>
    );
};

export default LoginComponent;