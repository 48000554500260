import React, { useContext, useState } from 'react';
// import { referals } from '../../../utils/referals';
import { UserContext } from '../UserContext';

import personal from '../../../images/LichKabinet/referals/personal.png'
import openlvl from '../../../images/LichKabinet/referals/openlevels.png'

import instgreen from '../../../images/LichKabinet/referals/instgreen.png'
import telegram from '../../../images/LichKabinet/referals/telegram.png'
import telegramgreen from '../../../images/LichKabinet/referals/telegramgreen.png'
import arrowleft from '../../../images/LichKabinet/transaction/left.png';
import arrowright from '../../../images/LichKabinet/transaction/right.png';



const itemsPerPage = 10;

const referals = []
const ReferalProgramComponent = () => {
    const [currentPage, setCurrentPage] = useState(1);

    const { user } = useContext(UserContext);
    const totalPages = Math.ceil(referals.length / itemsPerPage);

    const changePage = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) return;
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const selectedReferals = referals.slice(startIndex, startIndex + itemsPerPage);

    return (
        <main className='referal-program-component'>
            <section className='referal-program-section-one'>
                <div className='referal-program-h1'>
                    <h1>Date</h1>
                    <h1>Name</h1>
                    <h1>Login</h1>
                    <h1>Top-up amount</h1>
                    <h1>Status</h1>
                    <h1>Contacts</h1>
                </div>
                <div className='all-referals'>
                    {selectedReferals.length === 0 ? (
                        <div className="no-transactions-message" style={{ padding: "0" }}>
                            No referrals yet
                        </div>
                    ) : (
                        selectedReferals.map((referral, index) => (
                            <div className='referal-user' key={index}>
                                <h2>{referral.date}</h2>
                                <h2>{referral.name}</h2>
                                <h2>{referral.login}</h2>
                                <h2>{referral.amount}</h2>
                                <h2>{referral.status}</h2>
                                <h2>
                                    {referral.inst === 1 && <img src={instgreen} alt='Instagram Green' />}
                                    {referral.telegram === 1 && <img src={telegramgreen} alt='Telegram Green' />}
                                </h2>
                            </div>
                        ))
                    )}
                </div>
                {referals.length > 0 && (
                    <div className='pagination-container'>
                        <button
                            onClick={() => changePage(currentPage - 1)}
                            disabled={currentPage === 1}
                            className='pagination-container-buttons-slide'
                        >
                            <img src={arrowleft} alt="Previous" />
                            Previous
                        </button>

                        {Array.from({ length: totalPages }, (_, index) => (
                            <button
                                key={index}
                                className={currentPage === index + 1 ? 'pagination-container-buttons active-page' : 'pagination-container-buttons un-active-page'}
                                onClick={() => changePage(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}

                        <button
                            onClick={() => changePage(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className='pagination-container-buttons-slide'
                        >
                            Next <img src={arrowright} alt="Next" />
                        </button>
                    </div>
                )}
            </section>
            <section className='referal-program-section-two'>
                {(user?.user?.referal != 'LuminaInvest' && user?.user?.referal) && <main className='referal-info'>
                    <section>
                        <p>Ruslan Mayer</p>
                        <span>Ваш спонсор</span>
                    </section>
                    <section><b>DNEPR9797</b> <img src={telegram} alt='Telegram' /></section>
                </main>}

                <main>
                    <img src={personal} alt='Personal' />
                    <div>
                        <h2>Personal invitations</h2>
                        <p>{user?.user?.countreferals}</p>
                    </div>
                </main>

                <main>
                    <img src={openlvl} alt='Open Levels' />
                    <div>
                        <h2>Open levels</h2>
                        <p>{user?.user?.lvl}</p>
                    </div>
                </main>
            </section>
        </main>
    );
};

export default ReferalProgramComponent;