import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import avatar from '../../../images/LichKabinet/settings/avatar.png';
import edit from '../../../images/LichKabinet/settings/edit.png';
import { UserContext } from '../UserContext';

const SettingsForm = () => {
    const [activeTab, setActiveTab] = useState('editProfile');
    const [visibleTab, setVisibleTab] = useState('editProfile');
    const [uploadedFile, setUploadedFile] = useState(null);
    const { user } = useContext(UserContext);

    const [dob, setDob] = useState(user?.user?.datebirth || '');
    const token = localStorage.getItem('token');

    const originalUserData = {
        name: user?.user?.name || '',
        username: user?.user?.username || '',
        password: '**********',
        email: user?.user?.email || '',
        dob: user?.user?.datebirth || '',
        presentadress: user?.user?.presentadress || '',
        permamentadress: user?.user?.permamentadress || '',
        city: user?.user?.city || '',
        postalcode: user?.user?.postalcode || '',
        country: user?.user?.country || '',
    };

    const [userData, setUserData] = useState(originalUserData);

    useEffect(() => {
        const timeoutId = setTimeout(() => setVisibleTab(activeTab), 500);
        return () => clearTimeout(timeoutId);
    }, [activeTab]);

    useEffect(() => {
        setDob(user?.user?.datebirth || '');
    }, [user]);

    const handleFileChange = (event) => {
        setUploadedFile(event.target.files[0]);
    };

    const handleSave = async (event) => {
        event.preventDefault();

        const updatedData = {};

        for (const [key, value] of Object.entries(originalUserData)) {
            if (value !== userData[key]) {
                updatedData[key] = userData[key];
            }
        }

        if (originalUserData.dob !== dob) {
            updatedData.dob = dob;
        }

        if (Object.keys(updatedData).length === 0 && !uploadedFile) {
            alert('No changes detected to save.');
            return;
        }

        const formData = new FormData();

        for (const [key, value] of Object.entries(updatedData)) {
            formData.append(key, value);
        }

        if (uploadedFile) {
            formData.append('documentUpload', uploadedFile);
        }

        try {
            console.log(formData)
            const response = await axios.post('https://web.fundlumina.com/api/edituser', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            window.location.reload();

            alert('All settings saved!');
        } catch (error) {
            console.error('Error saving settings:', error);
            alert('There was an error saving your settings. Please try again later.');
        }
    };


    const renderInputField = (id, label, type = 'text') => (
        <div className='form-group'>
            <label htmlFor={id}>{label}</label>
            <input
                id={id}
                name={id}
                type={type}
                value={userData[id]}
                onChange={(e) => setUserData(prev => ({ ...prev, [id]: e.target.value }))}
                placeholder={originalUserData[id]}
            />
        </div>
    );

    const getInitials = (username) => {
        if (!username) return '';
        const words = username.split(' ');
        if (words.length === 1) {
            return words[0].charAt(0).toUpperCase();
        }
        return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
    };


    return (
        <div className='settings-form-component'>
            <section className='settings-header-h1'>
                <h1
                    className={activeTab === 'editProfile' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('editProfile')}
                >
                    Edit Profile
                </h1>
                <h1
                    className={activeTab === 'preferences' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('preferences')}
                >
                    Preferences
                </h1>
                <h1
                    className={activeTab === 'security' ? 'active-tab' : 'inactive-tab'}
                    onClick={() => setActiveTab('security')}
                >
                    Security
                </h1>


            </section>
            <div className={`transition-wrapper ${visibleTab === activeTab ? 'visible' : ''}`}>
                {visibleTab === 'editProfile' && (
                    <section className='edit-profile-settings'>
                        <div className='profile-avatar'>
                            {/* <img src={avatar} className='avatar-settings' alt='Avatar' /> */}
                            <div className='initials'>
                                {getInitials(user?.user?.username)}
                            </div>
                          
                            <img src={edit} alt='Edit Icon' className='edit-pen' />
                        </div>

                        <form className='profile-form' onSubmit={handleSave}>
                            {renderInputField('name', 'Your Name', 'text', userData.name)}
                            {renderInputField('username', 'User Name', 'text', userData.username)}
                            {renderInputField('email', 'Email', 'email', userData.email)}
                            {renderInputField('password', 'Password', 'password', '**********')}
                            <div className='form-group'>
                                <label htmlFor='dob'>Date of Birth</label>
                                <input
                                    id='dob'
                                    name='dob'
                                    type='date'
                                    value={dob}
                                    onChange={(e) => {
                                        setDob(e.target.value);
                                        setUserData(prev => ({ ...prev, dob: e.target.value }));
                                    }}
                                />
                            </div>
                            {renderInputField('presentadress', 'Present Address', 'text', userData.presentadress)}
                            {renderInputField('permamentadress', 'Permanent Address', 'text', userData.permamentadress)}
                            {renderInputField('city', 'City', 'text', userData.city)}
                            {renderInputField('postalcode', 'Postal Code', 'text', userData.postalcode)}
                            {renderInputField('country', 'Country', 'text', userData.country)}
                            <div className='form-group'>
                                <label htmlFor='documentUpload'>Upload Document</label>
                                <input
                                    id='documentUpload'
                                    name='documentUpload'
                                    type='file'
                                    onChange={handleFileChange}
                                />
                                <h2 className='settings-form-gray-text'>
                                    If you are unable to upload the required documents in your Personal Account,
                                    you can send them to the email address payments@alpari.im, specifying your
                                    Personal Account number (14043850) in the subject line of the email.
                                </h2>
                            </div>

                            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                                <button type='submit' className='button-save-settings'>Save</button>
                            </div>
                        </form>
                    </section>
                )}

                {visibleTab === 'preferences' && (
                    <section className='preferences-settings'>
                        <h2>Coming soon...</h2>
                    </section>
                )}

                {visibleTab === 'security' && (
                    <section className='security-settings'>
                        <h2>Coming soon...</h2>
                    </section>
                )}
            </div>
        </div>
    );
};

export default SettingsForm;