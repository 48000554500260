import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

import avatar from '../../images/LichKabinet/avatar.png';

import homepage from '../../images/LichKabinet/profileimg/homepage.png';
import transactions from '../../images/LichKabinet/profileimg/transactions.png';
import investment from '../../images/LichKabinet/profileimg/investments.png';
import myaccount from '../../images/LichKabinet/profileimg/myaccounts.png';

import account from '../../images/LichKabinet/profileimg/account.png';
import myprivileges from '../../images/LichKabinet/profileimg/myprivilege.png';
import setting from '../../images/LichKabinet/profileimg/setting.png';

import homepageactive from '../../images/LichKabinet/profileimg/homepageactive.png';
import transactionsactive from '../../images/LichKabinet/profileimg/transactionsactive.png';
import investactiv from '../../images/LichKabinet/profileimg/investactive.png';
import myaccountactiv from '../../images/LichKabinet/profileimg/accountactive.png';

import accountactive from '../../images/LichKabinet/profileimg/accountactiveactive.png';
import myprivilegesactive from '../../images/LichKabinet/profileimg/myprivilegesactive.png';
import settingactive from '../../images/LichKabinet/profileimg/settingactive.png';
import porfolio from '../../images/LichKabinet/profileimg/porfolio.png';
import { UserContext } from './UserContext';

function MyprofileComponent() {
    const location = useLocation();

    const { user } = useContext(UserContext);
    const isActive = (path) => location.pathname === path;

    const linkClass = (path) => isActive(path) ? 'link link-active' : 'link';

    const showAlert = () => {
        alert("Coming soon");
    };


    const getInitials = (username) => {
        if (!username) return '';
        const words = username.split(' ');
        if (words.length === 1) {
            return words[0].charAt(0).toUpperCase();
        }
        return (words[0].charAt(0) + words[1].charAt(0)).toUpperCase();
    };

    return (
        <main className='my-profile-main'>
            <div className='my-profile-component'>
                <section className='my-profile-section-one'>
                    <h2>Profile</h2>
                    {/* <img src={avatar} alt="Avatar" /> */}
                    <div className='initials'>
                        {getInitials(user?.user?.username)}
                    </div>
                    <h1>{user?.user?.username}</h1>
                    <Link to='/lk/mysettings' className='link'>Edit Profile</Link>
                </section>

                <section className='my-profile-lvl'>
                    <img src={porfolio} />
                    <div>
                        <h1>{user?.user?.lvl} level</h1>
                        <p>Current qualification</p>
                    </div>
                </section>
                <section className='my-profile-section-two'>
                    <Link to='/lk' className={linkClass('/lk')}>
                        <img src={isActive('/lk') ? homepageactive : homepage} alt="Home page" />
                        Home page
                    </Link>
                    <Link to='/lk/myaccounts' className={linkClass('/lk/myaccounts')}>
                        <img src={isActive('/lk/myaccounts') ? myaccountactiv : myaccount} alt="My accounts" />
                        My accounts
                    </Link>
                    <Link to='/lk/mytransactions' className={linkClass('/lk/mytransactions')}>
                        <img src={isActive('/lk/mytransactions') ? transactionsactive : transactions} alt="Transactions" />
                        Transactions
                    </Link>
                    <Link to='/lk/myinvestments' className={linkClass('/lk/myinvestments')}>
                        <img src={isActive('/lk/myinvestments') ? investactiv : investment} alt="Investments" />
                        Investments
                    </Link>

                    <Link to='/lk/referalprogram' className={linkClass('/lk/referalprogram')}>
                        <img src={isActive('/lk/referalprogram') ? accountactive : account} alt="Account Operations" />
                        Referal program
                    </Link>

                    <Link to='/lk/mysettings' className={linkClass('/lk/mysettings')}>
                        <img src={isActive('/lk/mysettings') ? settingactive : setting} alt="Setting" />
                        Personal data
                    </Link>
                    <Link to='/lk/mydeposit' className={linkClass('/lk/mydeposit')}>
                        <img src={isActive('/lk/mydeposit') ? myprivilegesactive : myprivileges} alt="My Privileges" />
                        Deposit program
                    </Link>

                </section>
            </div>
        </main>
    );
}

export default MyprofileComponent;
