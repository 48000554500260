import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import logo from '../../images/Header/headerlogo.png';

const ForgotPassComponent = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const params = new URLSearchParams(location.search);

    const referal = params.get('id') || '';
    const handleResetPassword = async () => {
        try {
            const response = await axios.get('https://web.fundlumina.com/api/sendemailreset', {
                params: {
                    email: email,
                },
            });
            if (response.status === 200) {
                alert("Password reset email sent successfully.");
                navigate(`/`); 
            }
            console.log("Password reset email sent:", response.data);
        } catch (error) {
            console.error("Error sending password reset email:", error);
        }
    };
    return (
        <div className="regestration-component">
            <img src={logo} className="absolut-regestration-component" onClick={() => { navigate(`/?id=${referal}`) }} />
            <main className="log-in-main">
                <h1 style={{ fontSize: "40px" }}>Reset Password for your personal account.</h1>

                <section style={{ width: "100%" }}>
                    <div className="log-in-inputs">
                        <input
                            placeholder="Personal Account Number or E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button onClick={handleResetPassword}>Reset Password</button>
                    </div>

                    <div className="regestration-component-end-div">
                        <Link to={`/login?id=${referal}`}>Log in</Link>
                    </div>
                </section>

                <footer>
                    <Link to="#">Privacy Policy</Link>
                    <Link to="#">Terms</Link>
                    <Link to="#">© 2023 LIF.</Link>
                </footer>
            </main>
        </div>
    );
};

export default ForgotPassComponent;