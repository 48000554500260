import React, { useContext, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import lif from '../../images/LichKabinet/header/lif.png';
import notification from '../../images/LichKabinet/header/notification.svg';
import settings from '../../images/LichKabinet/header/settings.svg';
import exit from '../../images/LichKabinet/header/exit.svg';
import lines from '../../images/Header/lines.png'



import homepage from '../../images/LichKabinet/profileimg/homepage.png';
import transactions from '../../images/LichKabinet/profileimg/transactions.png';
import investment from '../../images/LichKabinet/profileimg/investments.png';
import myaccount from '../../images/LichKabinet/profileimg/myaccounts.png';

import account from '../../images/LichKabinet/profileimg/account.png';
import myprivileges from '../../images/LichKabinet/profileimg/myprivilege.png';
import setting from '../../images/LichKabinet/profileimg/setting.png';

import homepageactive from '../../images/LichKabinet/profileimg/homepageactive.png';
import transactionsactive from '../../images/LichKabinet/profileimg/transactionsactive.png';
import investactiv from '../../images/LichKabinet/profileimg/investactive.png';
import myaccountactiv from '../../images/LichKabinet/profileimg/accountactive.png';

import accountactive from '../../images/LichKabinet/profileimg/accountactiveactive.png';
import myprivilegesactive from '../../images/LichKabinet/profileimg/myprivilegesactive.png';
import settingactive from '../../images/LichKabinet/profileimg/settingactive.png';
import porfolio from '../../images/LichKabinet/profileimg/porfolio.png';
import { UserContext } from './UserContext';
import Notifications from './Notifications';
const LichKabinetHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [isNotificationsVisible, setNotificationsVisible] = useState(false);
    const { user } = useContext(UserContext);


    const isActive = (path) => location.pathname === path;

    const linkClass = (path) => isActive(path) ? 'link link-active' : 'link';

    const showAlert = () => {
        alert("Coming soon");
    };
    const closePopUp = () => {
        localStorage.removeItem('token');
        setOverlayVisible(false)
    }

    const toggleNotifications = () => {
        setNotificationsVisible(!isNotificationsVisible);
    };
    const copyReferralLink = () => {
        const fullLink = `https://fundlumina.com?id=${user?.user?.user_id}`;
        navigator.clipboard.writeText(fullLink).then(() => {
            alert('Ссылка скопирована в буфер обмена!');
        }).catch(err => {
            console.error('Ошибка копирования:', err);
        });
    };

    const getHeaderText = (pathname) => {
        switch (pathname) {
            case '/lk':
                return 'Overview';
            case '/lk/mytransactions':
                return 'Transactions';
            case '/lk/mysettings':
                return 'Personal data';
            case '/lk/myinvestments':
                return 'Investments';
            case '/lk/myaccounts':
                return 'My Accounts';
            case '/lk/mydeposit':
                return 'Deposit program'
            default:
                return 'Overview';
        }
    };

    const headerText = getHeaderText(location.pathname);

    return (
        <main className='lich-kabinet-header'>
            <img src={lines} className='lines-heade-lich' onClick={() => setOverlayVisible(!isOverlayVisible)} /> {/* Toggle overlay visibility */}
            <section className='header-section-one'>
                <div>
                    <img src={lif} alt='LVF Logo' onClick={() => { navigate('/') }} style={{ cursor: "pointer" }} />
                </div>
                <h1 className='overwiev-header'>{headerText}</h1>
            </section>

            <section className='sponsor-section'>
                <div>
                    Моя реферальная ссылка:
                    <span
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={copyReferralLink}
                    >
                        https://fundlumina.com
                    </span>
                </div>
            </section>


            <section className='header-section-two'>
                <div className='header-section-div-two'>
                <img
                        src={notification}
                        alt='Notification Icon'
                        className='quest-header'
                        onClick={toggleNotifications}
                    />
                    <img src={settings} alt='Settings Icon' onClick={() => { navigate('/lk/mysettings') }} />
                    <img
                        src={exit}
                        alt='Exit Icon'
                        onClick={() => {
                            localStorage.removeItem('token');
                            navigate('/'); 
                        }}
                    />
                </div>
                {isNotificationsVisible && (
                    <div className='notifications-dropdown'>
                       <Notifications/>
                    </div>
                )}
            </section>
            {isOverlayVisible && (
                <section className='overlay-lich-cabinet'>
                    <section className='my-profile-section-two'>
                        <Link to='/lk' className={linkClass('/lk')}>
                            <img src={isActive('/lk') ? homepageactive : homepage} alt="Home page" />
                            Home page
                        </Link>
                        <Link to='/lk/myaccounts' className={linkClass('/lk/myaccounts')}>
                            <img src={isActive('/lk/myaccounts') ? myaccountactiv : myaccount} alt="My accounts" />
                            My accounts
                        </Link>
                        <Link to='/lk/mytransactions' className={linkClass('/lk/mytransactions')}>
                            <img src={isActive('/lk/mytransactions') ? transactionsactive : transactions} alt="Transactions" />
                            Transactions
                        </Link>
                        <Link to='/lk/myinvestments' className={linkClass('/lk/myinvestments')}>
                            <img src={isActive('/lk/myinvestments') ? investactiv : investment} alt="Investments" />
                            Investments
                        </Link>

                        <Link to='/lk/referalprogram' className={linkClass('/lk/referalprogram')}>
                            <img src={isActive('/lk/referalprogram') ? accountactive : account} alt="Account Operations" />
                            Referal program
                        </Link>

                        <Link to='/lk/mysettings' className={linkClass('/lk/mysettings')}>
                            <img src={isActive('/lk/mysettings') ? settingactive : setting} alt="Setting" />
                            Personal data
                        </Link>
                        <Link to='/lk/mydeposit' className={linkClass('/lk/mydeposit')}>
                            <img src={isActive('/lk/mydeposit') ? myprivilegesactive : myprivileges} alt="My Privileges" />
                            Deposit program
                        </Link>
                        <Link to='#' onClick={closePopUp}>
                            <img src={exit} className='exit-overlay-header' />
                        </Link>
                    </section>
                </section>)}
        </main>
    );
};

export default LichKabinetHeader;