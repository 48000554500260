import React from 'react';
import { useInView } from 'react-intersection-observer';
import dots from '../../images/ThirdComponent/background1.png'
import circle from '../../images/ThirdComponent/background2.jpg'
function ThirdComponent() {
    const { ref: employeesRef, inView: employeesInView } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    return (
        <div className='third-component' id='about' ref={employeesRef}>
            <p>Invest Fund Lumina is an innovative financial fund established to provide a wide range
                of services in the financial sector.
                Our key areas include investments, asset management, and brokerage services.</p>
            {/* dots-back */}
            <section ref={employeesRef} className={employeesInView ? 'animate-on-onesec dots-back' : 'opacity-none dots-back'}>
                <h1 style={{ color: "rgba(28, 74, 49, 1)" }}>30+ </h1> <span>Number of countries we invest</span>
                <img src={dots} />
            </section>
            <section ref={employeesRef} className={employeesInView ? 'animate-on-dropdown' : 'opacity-none'} style={{ backgroundColor: "rgba(235, 255, 187, 0.5)" }}>
                <h1>50+ </h1> <span>Number of employees</span>

            </section>

            <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", justifyContent: "space-around", width: "100%" }}>
                <section ref={employeesRef} className={employeesInView ? 'animate-on-godowntwosec' : 'opacity-none'}><h1 style={{ color: "rgba(28, 74, 49, 1)" }}>150+ </h1><span>Number of investments</span></section>
                <section ref={employeesRef} className={employeesInView ? 'animate-on-godowntrisec' : 'opacity-none'} style={{ backgroundColor: "rgba(235, 255, 187, 0.5)" }}><h1>85%</h1><span>Percentage of successful startups</span></section>
                <section ref={employeesRef} className={employeesInView ? 'animate-on-onesec circle-back' : 'opacity-none circle-back'}>
                    <h1>$1 MILLION</h1><span>Average investment size</span>  <img src={circle} />
                </section>
            </div>
        </div>
    );
}

export default ThirdComponent;
