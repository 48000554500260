import React from 'react';
import LichKabinetHeader from '../components/LichKabinet/LichKabinetHeader';
import MyprofileComponent from '../components/LichKabinet/MyprofileComponent';
import SendMoney from '../components/LichKabinet/homepage/SendMoney';
import MystatisticComponent from '../components/LichKabinet/homepage/MystatisticComponent';
import BalanceHistoryComponent from '../components/LichKabinet/homepage/BalanceHistoryComponent';
import MyAccounts from '../components/LichKabinet/homepage/MyAccounts';
import LastTransactions from '../components/LichKabinet/homepage/LastTransactions';
import MyinvestmentsComponent from '../components/LichKabinet/homepage/MyinvestmentsComponent';
import ExpenseStatistics from '../components/LichKabinet/homepage/ExpenseStatistics';
import ReferalLink from '../components/LichKabinet/ReferalLink';
import Notifications from '../components/LichKabinet/Notifications';

function LichCabinet() {
    return (
        <div className='lich-kabinet'>
            <LichKabinetHeader />
            <ReferalLink />
            <div style={{ display: "flex", gap: "20px" }}>
                <MyprofileComponent />
                <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", alignContent: "flex-start" }}>
                    <div className='top-three-lich-kabinet'>
                        <MystatisticComponent />
                        <SendMoney />
                        <BalanceHistoryComponent />
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px", maxWidth: "1100px" }}>
                            <MyAccounts />
                            <LastTransactions />
                            <MyinvestmentsComponent />
                            <ExpenseStatistics />
                        </div>
                        <div className='notification-lich-kab'>
                        <Notifications />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LichCabinet;
