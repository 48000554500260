import React from 'react';
import LoginComponent from '../components/Regestration/LogInComponent';


function LogInPage() {
    return (
        <div>
            <LoginComponent />
        </div>
    );
}

export default LogInPage;
