
import mybalance from '../../../images/LichKabinet/mystatistic/mybalance.svg'
import income from '../../../images/LichKabinet/mystatistic/income.svg'
import expense from '../../../images/LichKabinet/mystatistic/expense.svg'
import total from '../../../images/LichKabinet/mystatistic/total.svg'

const MystatisticComponent = () => {
 
    return (
        <main className='my-statistic-account'>
            <h1 className='h1-statistic-account' style={{marginBottom:"0"}}>My statistics</h1>
            <div className='my-statistic-account-div-one'>
                <section>
                    <img src={mybalance} />
                    <div>
                        <h2>My Balance</h2>
                        <p>$0</p>
                    </div>
                </section>
                <section>
                    <img src={income} />
                    <div>
                        <h2>Income</h2>
                        <p>$0</p>
                    </div>
                </section>
            </div>


            <div className='my-statistic-account-div-two'>
                <section>
                    <img src={expense} />
                    <div>
                        <h2>Expense</h2>
                        <p>$0</p>
                    </div>
                </section>
                <section>
                    <img src={total} />
                    <div>
                        <h2>Total Saving</h2>
                        <p>$0</p>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default MystatisticComponent;
