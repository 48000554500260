import { Link, useLocation } from "react-router-dom";
import cardchip from '../../../images/LichKabinet/myaccounts/Chip_Card.png';
import master from '../../../images/LichKabinet/myaccounts/masteroncard.png';

import masterwhite from '../../../images/LichKabinet/myaccounts/masteroncardwhite.png'
import cardchipwhite from '../../../images/LichKabinet/myaccounts/Chip_Cardwhite.png';

import lumina from '../../../images/LichKabinet/myaccounts/lumina.png'
import luminagrey from '../../../images/LichKabinet/myaccounts/luminagrey.png'
import { useContext } from "react";
import { UserContext } from "../UserContext";
const MyAccountsComponent = () => {
    const location = useLocation();
    const { user } = useContext(UserContext);


    const formatDate = (isoString) => {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(2); 

        return `${month}/${day}/${year}`;
    };
    return (
        <main className="my-account-component">
            <section className="top-statistic-account">
                <h1 className='h1-statistic-account'>My accounts</h1>
                {location.pathname === '/lk' && <Link to='/lk/myaccounts'>See All</Link>}
                {/* {location.pathname === '/lk/mytransactions' && <Link to='/lk/myaccounts'>+ Add accounts</Link>} */}
            </section>

            <section
                className="my-accounts-cards"
                style={{
                    justifyContent: location.pathname === '/lk' ? 'center' : 'flex-start'
                }}
            >
                <div style={{ position: "relative" }}>
                    <section className="card-my-account">
                        <div className="card-top">
                            <h2>Total Balance</h2>
                        </div>

                        <div className="card-middle">
                            <h1>${user?.user?.totalbalance}</h1>
                        </div>
                    </section>

                    <div className="cart-bottom-bottom">
                        <div className="cart-bottom">
                            <div><h1>CARD HOLDER</h1> <h2>{user?.user?.username}</h2></div>
                            <div><h1>Registration date</h1> <h2>{formatDate(user?.user?.createdAt)}</h2></div>
                        </div>
                    </div>
                    <img src={lumina} className="lumina-absolute" />

                </div>

                {(location.pathname === '/lk/mytransactions' || location.pathname === '/lk/myaccounts') && <div style={{ position: "relative", border: "1px solid #9DA19F", borderRadius: "25px" }}>
                    <section className="card-secondarymy-account">
                        <div className="card-top">
                            <h2>Referral balance</h2>
                        </div>

                        <div className="card-middle">
                            <h1>${user?.user?.referalbalance}</h1>
                        </div>
                    </section>

                    <div className="cart-bottomsecond-bottom ">
                        <div className="cart-bottom">
                            <div><h1>CARD HOLDER</h1> <h2>{user?.user?.username}</h2></div>
                            <div><h1>Registration date</h1> <h2>{formatDate(user?.user?.createdAt)}</h2></div>
                        </div>
                    </div>
                    <img src={luminagrey} className="lumina-absolute" />
                </div>
                }
                {location.pathname === '/lk/myaccounts' && <div style={{ position: "relative", border: "1px solid #9DA19F", borderRadius: "25px" }}>
                    <section className="card-secondarymy-account">
                        <div className="card-top">
                            <h2>Invest balance</h2>
                        </div>

                        <div className="card-middle">
                            <h1>${user?.user?.investbalance}</h1>
                        </div>
                    </section>

                    <div className="cart-bottomsecond-bottom ">
                        <div className="cart-bottom">
                            <div><h1>CARD HOLDER</h1> <h2>{user?.user?.username}</h2></div>
                            <div><h1>Registration date</h1> <h2>12/08/24</h2></div>
                        </div>
                    </div>
                    <img src={luminagrey} className="lumina-absolute" />
                </div>
                }
            </section>

        </main>
    );
};

export default MyAccountsComponent;
