import React, { useContext } from 'react';

import copy from '../../images/LichKabinet/copy.png'
import { UserContext } from './UserContext';

const ReferalLink = () => {

    const { user } = useContext(UserContext);

    const copyReferralLink = () => {
        const fullLink = `https://fundlumina.com?id=${user?.user?.user_id}`;
        navigator.clipboard.writeText(fullLink).then(() => {
            alert('Ссылка скопирована в буфер обмена!');
        }).catch(err => {
            console.error('Ошибка копирования:', err);
        });
    };
    return (
        <div className='referal-link-component' onClick={copyReferralLink}>
            <div>
                Моя реферальная ссылка:
                <span
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    
                >
                    https://fundlumina.com
                </span>
            </div>
            <img src={copy} className='copytrading' />
        </div>
    );
};

export default ReferalLink;
