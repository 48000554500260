import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import logo from '../../images/Header/headerlogo.png';

const RegistrationComponent = () => {
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [acceptTermsChecked, setAcceptTermsChecked] = useState(false);
    const [news, setNewsletterChecked] = useState(false);
    const params = new URLSearchParams(location.search);
    const referal = params.get('id') || 'LuminaInvest';
    const navigate = useNavigate();

    const handleRegister = async () => {
        const nameCityCountryRegex = /^[\p{L}\s'-]+$/u;

        if (!name || !nameCityCountryRegex.test(name)) {
            alert('Please enter a valid name without numbers.');
            return;
        }

        if (!lastname || !nameCityCountryRegex.test(lastname)) {
            alert('Please enter a valid last name without numbers.');
            return;
        }

        if (!country || !nameCityCountryRegex.test(country)) {
            alert('Please enter a valid country name without numbers.');
            return;
        }

        if (!city || !nameCityCountryRegex.test(city)) {
            alert('Please enter a valid city name without numbers.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert('Please enter a valid email address.');
            return;
        }

        const phoneRegex = /^\+?[0-9]{7,15}$/;
        if (!phoneRegex.test(phone)) {
            alert('Please enter a valid phone number.');
            return;
        }

        if (!acceptTermsChecked) {
            alert('You must accept the terms to register.');
            return;
        }

        try {
            const username = `${lastname} ${name}`;
            await axios.post('https://web.fundlumina.com/api/register', {
                referal, name, lastname, username, email, phone, country, city, news
            }, { withCredentials: true });
            alert('Registration successful!');
            navigate("/login");
        } catch (error) {
            alert('Registration failed. Please check your input.');
            console.error('Registration error:', error);
        }
    };

    return (
        <div className="regestration-component">
            <img src={logo} className="absolut-regestration-component" onClick={() => { navigate(`/?id=${referal}`) }} />
            <main className="registration-main">
                <h1>Sign up to open a trading account</h1>
                <section>
                    <p className="regestration-component-text-first">
                        Trade hundreds of assets from around the world on a convenient platform from a reliable, award-winning broker.
                    </p>
                    <div>
                        <input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        <input placeholder="Last Name" value={lastname} onChange={(e) => setLastname(e.target.value)} />
                        <input placeholder="Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        <input placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <input placeholder="Country of Residence" value={country} onChange={(e) => setCountry(e.target.value)} />
                        <input placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                        <section className="section-checkbox-reg">
                            <input type="checkbox" id="check1" checked={acceptTermsChecked} onChange={(e) => setAcceptTermsChecked(e.target.checked)} />
                            <label htmlFor="check1">
                                I accept the terms of the Client Agreement and the Privacy Policy and consent to the processing of personal data. I confirm that I am at least 18 years old.
                            </label>
                        </section>
                        <section className="section-checkbox-reg">
                            <input type="checkbox" id="check2" checked={news} onChange={(e) => setNewsletterChecked(e.target.checked)} />
                            <label htmlFor="check2">I consent to receive informational newsletters from the company.</label>
                        </section>
                        <h2 className="regestration-component-text-second">
                            By completing the registration, I confirm the accuracy of the information provided and agree to submit supporting documents.
                        </h2>
                        <button onClick={handleRegister}>Sign Up</button>
                    </div>

                    <div className="regestration-component-end-div">
                        <p>Having trouble with registration? <span>Contact us.</span></p>
                        <Link to={`/login?id=${referal}`}>Log in</Link>
                    </div>
                </section>

                <footer>
                    <Link to="#">Privacy Policy</Link>
                    <Link to="#">Terms</Link>
                    <Link to="#">© 2023 LIF.</Link>
                </footer>
            </main>
        </div>
    );
};

export default RegistrationComponent;