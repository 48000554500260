import React from 'react';
import { UserProvider } from './UserContext'; 

const UserProviderRoute = ({ children }) => {
  return (
    <UserProvider>
      {children}
    </UserProvider>
  );
};

export default UserProviderRoute;