import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './routes';
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from './components/Regestration/PrivateRoute';
import UserProviderRoute from './components/LichKabinet/UserProviderRoute';

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {publicRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={<route.Component />} />
        ))}

        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={

              <PrivateRoute>
                <UserProviderRoute>
                  <route.Component />
                </UserProviderRoute>
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;