import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('https://web.fundlumina.com/api/getbytoken', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                const responseTransaction = await axios.get('https://web.fundlumina.com/api/gettransaction', {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })

                setUser(response.data);
                setTransactions(responseTransaction.data);
                console.log(response.data.user.username)
                console.log(response.data.user.createdAt)
            } catch (error) {
                console.error("Failed to fetch user data:", error);
                setUser(null);
            }
        };

        fetchUserData();
    }, [token]);
    return (
        <UserContext.Provider value={{ user,transactions }}>
            {children}
        </UserContext.Provider>
    );
};