import DepositProgramPage from "./pages/DepositProgramPage"
import ForgotPassword from "./pages/ForgotPassword"
import LichCabinet from "./pages/LichCabinet"
import LogInPage from "./pages/LogInPage"
import Main from "./pages/Main"
import MyAccountsPage from "./pages/MyAccounts"
import MyInvestments from "./pages/MyInvestments"
import MyTransaction from "./pages/MyTransaction"
import ReferalProgram from "./pages/ReferalProgram"
import RegestrationPage from "./pages/RegestrationPage"
import ResetPassword from "./pages/ResetPassword"
import Settings from "./pages/Settings"
import { ACCOUNTS_ROUTE, INVEST_ROUTE, LICH_ROUTE, MAIN_ROUTE, SETTINGS_ROUTE, TRANS_ROUTE, DEPOSIT_ROUTE, REFERAL_ROUTE, REG_ROUTE, LOGIN_ROUTE, FORGOT_ROUTE, RESET_ROUTE } from "./utils/consts"


export const publicRoutes = [
    {
        path: MAIN_ROUTE,
        Component: Main

    },
    {
        path: REG_ROUTE,
        Component: RegestrationPage

    },

    {
        path: LOGIN_ROUTE,
        Component: LogInPage

    },
    {
        path: FORGOT_ROUTE,
        Component: ForgotPassword

    },

    {
        path: RESET_ROUTE,
        Component: ResetPassword

    },


]

export const privateRoutes = [
    {
        path: LICH_ROUTE,
        Component: LichCabinet

    },

    {
        path: TRANS_ROUTE,
        Component: MyTransaction

    },

    {
        path: INVEST_ROUTE,
        Component: MyInvestments

    },

    {
        path: SETTINGS_ROUTE,
        Component: Settings

    },
    {
        path: ACCOUNTS_ROUTE,
        Component: MyAccountsPage

    },
    {
        path: DEPOSIT_ROUTE,
        Component: DepositProgramPage

    },
    {
        path: REFERAL_ROUTE,
        Component: ReferalProgram

    },



]