import React from 'react';
import RegestrationComponent from '../components/Regestration/RegestrationComponent';


function RegestrationPage() {
    return (
        <div>
            <RegestrationComponent />
        </div>
    );
}

export default RegestrationPage;
