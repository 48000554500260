import React from 'react';
import ForgotPassComponent from '../components/Regestration/ForgotPassComponent';


function ForgotPassword() {
    return (
        <div>
            <ForgotPassComponent />
        </div>
    );
}

export default ForgotPassword;
